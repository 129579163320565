export const environment = {
  production: true,
  referecandidateapiurl: 'https://devapis.globalhuntindia.com/run/',
  source: 'RecuriterRefCheck',
  externalPortalUrl: 'https://dev.globalhuntindia.com',
  redirectUrl: 'https://devcandidate.globalhuntindia.com/',
  domain: ".globalhuntindia.com",
  apiKey:'iLtsAG35Fd2kcWgbv3Jti169wcg6Jm2P1vpijVAM'
};

